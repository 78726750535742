import { createI18n } from 'vue-i18n'
import de from '../locales/de.json'
import nl from '../locales/nl.json'

export default defineNuxtPlugin(({ vueApp }) => {
    const localeCode = useLocaleCode();

    const i18n = createI18n({
        legacy: false,
        globalInjection: true,
        locale: localeCode.value ?? 'en',
        fallbackLocale: 'en',
        messages: {
            de,
            nl,
        }
    });

    vueApp.use(i18n);
})
